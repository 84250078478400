var Carousel = {
    setupSwipeSupport: function () {
        $('.Carousel-carousel').each(function () {
            var $carousel = $(this),
                hammertime = new Hammer(this, {
                    recognizers: [
                        [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]
                    ]
                });
            hammertime.on('swipeleft', function () {
                $carousel.carousel('next');
            });

            hammertime.on('swiperight', function () {
                $carousel.carousel('prev');
            });
        });
    },

    lazyLoad: function () {
        $("img[data-carouselitem-srcset]").each(function (i, v) {
            $(this).attr("srcset", $(this).data("carouselitem-srcset"));
            $(this).attr("src", $(this).data("carouselitem-src"));

            $(this).removeAttr("data-carouselitem-srcset");
            $(this).removeAttr("data-carouselitem-src");
        });
    },

    init: function () {
        Carousel.lazyLoad();
        Carousel.setupSwipeSupport();
    }


}
$(function () {
    Carousel.init();
});
